.menu-button {
    visibility: hidden;
    position: fixed;
    top: 15px;
    right: 10px;
    z-index: 2000;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    outline: none;
    background-color: var(--second-color);
}

.stripe {
    width: 24px;
    height: 1px;
    background-color: var(--the-white);
}

.exit-cross {
    color: var(--the-white);
}

@media only screen and (max-width: 1025px) {
    .menu-button {
        visibility: visible;
    }
}