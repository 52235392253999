.waking-up {
    position: absolute;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--see-through);
}

.waking-up div {
    margin: 20px 0;
}

.loading-icon {
    position: relative;
    margin: 40px auto;
    height: 100px;
    width: 100px;
}

.first-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    margin: 0;
    padding: 0;
    height: 100px;
    border-radius: 50%;
    border: 5px solid var(--the-black);
    border-top: 5px solid #248ec2;
    animation-name: spin;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
  }