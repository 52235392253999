.bookingContainer {
  width: 60%;
  padding: 0 0 20px 0;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: 1px solid var(--the-black);
}

.divider {
  border: 1px solid var(--second-color);
  width: 90%;
  margin: 20px auto;
}

.atBanner h2 {
  color: var(--the-black);
}

.atBanner p {
  margin-bottom: 0;
}

.hr {
  width: 90%;
  height: 1px;
  margin: 20px auto;
  background-color: var(--the-black);
  border: none;
}

.bookingForm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.bookingForm input,
select,
.addresses > div > label > input {
  width: 90%;
  margin: 10px auto;
  padding: 10px;
  border: none;
  -webkit-appearance: none;
}

.bookingForm select {
  background-color: white;
  width: 94%;
}

.bookingForm input[type="date"] {
  display: block;
  width: 94%;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;
}

.submitButton {
  width: 90%;
  margin: 10px auto;
  padding: 10px;
  transition: 0.5s;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid var(--the-black);
  background-color: var(--second-color);
  color: white;
  -webkit-appearance: none;
  outline: none;
}

.submitButton:hover {
  background-color: var(--the-black);
  color: var(--the-white);
}

.bookingForm label {
  width: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.addresses {
  width: 94%;
  margin: auto;
  box-sizing: border-box;
}

.addresses > div > button {
  background-color: var(--second-color);
  border: none;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-family: inherit;
  cursor: pointer;
  margin: 10px auto;
}

.bookingLabel {
  width: 45%;
  text-align: left;
  margin-left: 10px;
}

.requiredIcon {
  width: 45%;
  text-align: right;
  color: red;
}

.textArea {
  width: 90%;
  margin: 10px auto;
  padding: 10px;
}

.checkbox-alignment {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  text-align: left;
}

.paypal-container {
  width: 70%;
  margin: 30px auto;
}

@media only screen and (max-width: 600px) {
  .bookingContainer {
    width: 98%;
    margin: 10px auto;
  }

  .paypal-container {
    width: 90%;
    margin: 10px auto;
  }
}
