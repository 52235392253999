.background-image {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -100;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-image: url('../../images/background-image.jpg');
    background-image: -webkit-image-set(url('../../images/background-image.webp') 1x);
    background-position: center;
    background-size: cover;
}

.b-image {
    height: 100vh;
}